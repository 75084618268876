<template>
  <section>
    <v-tabs right>
      <v-tab :key="rolesAndPermissions">Auth0 Roles and Permissions</v-tab>
      <v-tab-item :key="rolesAndPermissions">
        <v-data-table
          :headers="roleHeaders"
          :items="roles"
          :show-select="false"
          item-key="name"
          class="elevation-1"
          :loading="loading"
          hide-default-footer
          show-expand
        >
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length">
              <v-data-table
                :headers="permissionsHeaders"
                :items="item.auth0Permissions"
                :items-per-page="-1"
                item-key="name"
                dense
              ></v-data-table>
            </td>
          </template>
        </v-data-table>
      </v-tab-item>
      <v-tab :key="controllerAttributes">Analytics Protected Endpoints</v-tab>
      <v-tab-item :key="controllerAttributes">
        <v-data-table :headers="endpointHeaders" :items="controllerPermissions" item-key="name"></v-data-table>
      </v-tab-item>
      <v-tab :key="controllerNoAttributes">Analytics Unprotected Endpoints</v-tab>
      <v-tab-item :key="controllerNoAttributes">
        <v-data-table :headers="endpointAuthorizationHeaders" :items="controllerNoPermissions" item-key="name"></v-data-table>
      </v-tab-item>
      <!-- <v-tab :key="unusedRolesPermissions">Undefined/Unused Permissions</v-tab>
      <v-tab-item :key="unusedRolesPermissions">
        <v-row>
          <v-col md="6">
            <v-card>
              <v-card-title>Undefined Permission Attributes</v-card-title>
              <v-data-table
                :headers="endpointHeaders"
                :items="undefinedPermissions"
                item-key="name"
                :loading="loadingPermissions"
              ></v-data-table>
            </v-card>
          </v-col>
          <v-col md="6">
            <v-card>
              <v-card-title>Defined Unused Permissions</v-card-title>
              <v-data-table
                :headers="permissionsHeaders"
                :items="unusedPermissions"
                item-key="name"
                :loading="loadingPermissions"
              ></v-data-table>
            </v-card>
          </v-col>
        </v-row>
      </v-tab-item>-->
    </v-tabs>
  </section>
</template>

<script>
import api from "../../../users/_api";
import ErrorSummary from "../../../../analytics/_mixins/error_utilities";
export default {
  mixins: [ErrorSummary],

  async created() {
    await this.getRolesAndPermissions();
    await this.getControllerPermissions();
    await this.getControllerNoPermissions();
    // this.loadingPermissions = true;
    // await this.getControllerUnusedPermissions(); //auth0 throttles at requests per 2 seconds
    // setTimeout(() => this.getControllerUndefinedPermissions(), 3000);
  },
  mounted() {},
  data() {
    return {
      roleHeaders: [
        { text: "Role Name", value: "name" },
        { text: "Description", value: "description" },
        { text: "Role Id", value: "id" }
      ],
      permissionsHeaders: [
        { text: "", value: "" },
        { text: "Permission Name", value: "name" },
        { text: "Description", value: "description" },
        { text: "API", value: "resourceServerName" }
      ],
      endpointHeaders: [
        { text: "Controller Name", value: "controllerName" },
        { text: "Method Name", value: "methodName" },
        { text: "Permission", value: "permission" }
      ],
      endpointAuthorizationHeaders: [
        { text: "Controller Name", value: "controllerName" },
        { text: "Method Name", value: "methodName" },
        { text: "Authorization Attribute", value: "permission" }
      ],
      roles: [],
      controllerPermissions: [],
      controllerNoPermissions: [],
      undefinedPermissions: [],
      unusedPermissions: [],
      loading: true,
      loadingPermissions: true,
      controllerAttributes: "Tab1",
      controllerNoAttributes: "Tab2",
      rolesAndPermissions: "Tab3",
      unusedRolesPermissions: "Tab4"
    };
  },
  methods: {
    async getRolesAndPermissions() {
      try {
        let response = await api.getRoles();
        this.roles = response;
        this.loading = false;
      } catch (error) {
        this.server_errors = this.errorSummary(error);
        this.loading = false;
      }
    },
    async getControllerPermissions() {
      try {
        let response = await api.getAnalyticsControllerPermissions();
        this.controllerPermissions = response;
        this.loading = false;
      } catch (error) {
        this.server_errors = this.errorSummary(error);
        this.loading = false;
      }
    },
    async getControllerNoPermissions() {
      try {
        let response = await api.getAnalyticsControllerNoPermissions();
        this.controllerNoPermissions = response;
        this.loading = false;
      } catch (error) {
        this.server_errors = this.errorSummary(error);
        this.loading = false;
      }
    }
    // async getControllerUndefinedPermissions() {
    //   try {
    //     let response = await api.getUndefinedPermissions();
    //     this.undefinedPermissions = response;
    //     this.loadingPermissions = false;
    //   } catch (error) {
    //     this.server_errors = this.errorSummary(error);
    //     this.loadingPermissions = false;
    //   }
    // },
    // async getControllerUnusedPermissions() {
    //   try {
    //     let response = await api.getUnusedAnalyticsPermissions();
    //     this.unusedPermissions = response;
    //     this.loading = false;
    //   } catch (error) {
    //     this.server_errors = this.errorSummary(error);
    //     this.loading = false;
    //   }
    // }
  }
};
</script>